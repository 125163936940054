import React from 'react';

// "settings": {
//     "namePath": "descendantRoles.clientList",
//     "valuePath": "metricsList.devTags.climateActivities",
//     "prefix": "",
//     "suffix": "",
//     "decimalPlaces": 1
// }

const Boilerplate = window.cccisd.boilerplate;
export default settings => {
    return ({ value, row }) => {
        const handleClick = event => {
            window.location = Boilerplate.url(`/checkUpFeedback/${value}`);
        };
        return (
            <div key={row} className="text-center">
                <div className="btn btn-primary" onClick={handleClick}>
                    Check-Up Feedback
                </div>
            </div>
        );
    };
};
