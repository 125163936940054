import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Header from '../../components/Header';
import Footer from 'js/components/CCUFooter';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class FluidLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    render() {
        return (
            <div>
                <Header className={this.props.className} />
                {this.props.showSecondaryNav && Fortress.auth() && (
                    <SecondaryNav className={this.props.className} navs={AppDefs.navs} routes={AppDefs.routes} />
                )}
                <div className="container-fluid">{this.props.children}</div>
                <Footer />
            </div>
        );
    }
}
