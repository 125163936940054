import React from 'react';
import PropTypes from 'prop-types';
import { playerWrapper } from 'cccisd-survey-common';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import practiceQuery from './practiceQuery.graphql';
import Loader from 'cccisd-loader';
import _get from 'lodash/get';
import style from './style.css';
import Logo from './header-logo.png';
const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const validate = (value, settings) => {
    let error;

    if (value > 0) {
        error = `There are ${value} required sections to be answered.`;
    }

    return error;
};

export class Player extends React.Component {
    static propTypes = {
        field: PropTypes.object,
        settings: PropTypes.object,
        isPreview: PropTypes.bool,
        pawnId: PropTypes.number,
        pawnHash: PropTypes.string,
        responseSet: PropTypes.number,
    };

    state = {
        loading: true,
    };

    componentDidMount() {
        const { isPreview } = this.props;

        if (isPreview) {
            return;
        }

        this._getAnswers();
    }

    componentWillUpdate(newProps, newState) {}

    _getAnswers = async () => {
        const { isPreview } = this.props;
        const practices = Appdefs.app.observationPractices;
        if (isPreview) {
            return;
        }

        const response = await client.query({
            query: practiceQuery,
            variables: {
                pawnId: this.props.pawnId,
                deploymentId: this.props.responseSet,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        const tableData = this.processRawData(response, practices);

        await this.setState({
            tableData,
            observation: _get(
                response,
                'data.flows.assignmentProgress.deployment.assignment.settings.observation'
            ),
            loading: false,
        });
    };

    formatPracticeLabel = label => {
        let formattedLabel;
        switch (label) {
            case 'practice1':
                formattedLabel = 'Observation Practice 1';
                break;
            case 'practice2':
                formattedLabel = 'Observation Practice 2';
                break;
            case 'practice3':
                formattedLabel = 'Observation Practice 3';
                break;
            case 'practice4':
                formattedLabel = 'Observation Practice 4';
                break;
            default:
                formattedLabel = 'Observation Practice';
                break;
        }
        return formattedLabel;
    };

    processRawData = (response, practices) => {
        const observation = _get(
            response,
            'data.flows.assignmentProgress.deployment.assignment.settings.observation'
        );

        const devTags = _get(response, 'data.flows.assignmentProgress.devTags');

        const currentPractice = practices[observation];

        const rows = Object.keys(currentPractice).map((key, i) => {
            return currentPractice[key];
        });

        const hasSecondSet = devTags.ObsDisruptive2 !== null ? true : false;
        let data = {};
        if (hasSecondSet) {
            // 2 row sets
            const firstSet = rows.filter(item => !item.devTag.includes('2'));
            const secondSet = rows.filter(item => item.devTag.includes('2'));

            const firstTable = firstSet.map(item => {
                return [
                    item.label,
                    devTags[item.devTag],
                    this._getScore(devTags[item.devTag], item.master_coder),
                    item.feedback,
                    item.master_coder,
                ];
            });
            const secondTable = secondSet.map(item => {
                return [
                    item.label,
                    devTags[item.devTag],
                    this._getScore(devTags[item.devTag], item.master_coder),
                    item.feedback,
                    item.master_coder,
                ];
            });

            data.firstTable = firstTable;
            data.secondTable = secondTable;
        } else {
            const rowSet = rows.filter(item => !item.devTag.includes('2'));

            const tableData = rowSet.map(item => {
                return [
                    item.label,
                    devTags[item.devTag],
                    this._getScore(devTags[item.devTag], item.master_coder),
                    item.feedback,
                    item.master_coder,
                ];
            });
            data.firstTable = tableData;
            data.secondTable = null;
        }
        return data;
    };

    _getScore = (userV, masterV) => {
        const userValue = parseInt(userV, 10);
        const masterValue = parseInt(masterV, 10);

        // check if any is 0
        if (+userValue === 0 && +masterValue === 0) {
            return 100;
        }

        if (+userValue === 0 || !+masterValue === 0) {
            return 0;
        }

        if (+userValue < +masterValue) {
            return Math.round((+userValue / +masterValue) * 100);
        }

        return Math.round((+masterValue / +userValue) * 100);
    };

    renderTitle = () => {
        const user = Fortress.user.acting.user.full_name;
        const { observation } = this.state;
        const practiceLabel = this.formatPracticeLabel(observation);
        return (
            <div className={style.titleBox}>
                <img className={style.logo} src={Logo} alt="LOGO" />
                <h4 className={style.title}>{practiceLabel}</h4>
                <div className={style.completedBy}>
                    <strong>Completed by:</strong> {user}
                </div>
            </div>
        );
    };

    renderTableHead = () => {
        return (
            <tr className="row">
                <th className={style.observationHead}>Observation</th>
                <th className={style.frequencyHead}>Frequency</th>
                <th className={style.reliabilityHead}>Reliability</th>
                <th className={style.feedbackHead}>Feedback</th>
            </tr>
        );
    };

    renderSubText = () => {
        return (
            <div>
                <h5 style={{ fontStyle: 'italic' }}>
                    <strong>How did you compare to the master coder?</strong>
                </h5>
                <p>
                    You want to be as close to 100% the same as the master coder as possible, but
                    85% is acceptable. If you have a lower percentage, watch the video again to see
                    if you pick up on what you missed or over-recorded.
                </p>
            </div>
        );
    };

    renderTableRows = number => {
        const { tableData } = this.state;

        if (number === 1) {
            const table = tableData.firstTable.map(row => {
                const color = this.getScoreColor(row[2]);
                return (
                    <tr>
                        <td className={style.observation}>{row[0]}</td>
                        <td className={style.frequency}>{row[1]}</td>
                        <td className={style.reliability} style={{ color: `${color}` }}>
                            {row[2]}%
                        </td>
                        <td>{row[3]}</td>
                    </tr>
                );
            });
            return table;
        }

        if (number === 2) {
            const table = tableData.secondTable.map(row => {
                const color = this.getScoreColor(row[2]);

                return (
                    <tr>
                        <td className={style.observation}>{row[0]}</td>
                        <td className={style.frequency}>{row[1]}</td>
                        <td className={style.reliability} style={{ color: `${color}` }}>
                            {row[2]}%
                        </td>
                        <td>{row[3]}</td>
                    </tr>
                );
            });
            return table;
        }
    };

    renderTables = () => {
        const user = Fortress.user.acting.user.full_name;
        const { tableData } = this.state;
        const { observation } = this.state;
        const practiceLabel = this.formatPracticeLabel(observation);
        if (tableData.firstTable && !tableData.secondTable) {
            const tableRows = this.renderTableRows(1);
            const omitEngagment = tableRows.filter((item, i) => i !== 5);
            const engagment = tableRows.filter((item, i) => i === 5);

            return (
                <>
                    <div className={style.pageWrapper}>
                        <Report downloadFilename={`${practiceLabel}_${user}`}>
                            <Page>
                                {this.renderTitle()}
                                <table className={`table table-bordered ${style.mainTable}`}>
                                    <thead>{this.renderTableHead()}</thead>
                                    <tbody>
                                        {omitEngagment.map(item => {
                                            return <>{item}</>;
                                        })}
                                    </tbody>
                                </table>
                                <table className={`table table-bordered ${style.mainTable}`}>
                                    <thead>{this.renderTableHead()}</thead>
                                    <tbody>
                                        {engagment.map(item => {
                                            return <>{item}</>;
                                        })}
                                    </tbody>
                                </table>
                                {this.renderSubText()}
                            </Page>
                        </Report>
                    </div>
                </>
            );
        }
        if (tableData.firstTable && tableData.secondTable) {
            const firstTable = this.renderTableRows(1);
            const firstOmitEngagment = firstTable.filter((item, i) => i !== 5);
            const firstEngagment = firstTable.filter((item, i) => i === 5);
            const secondTable = this.renderTableRows(2);
            const secondOmitEngagment = secondTable.filter((item, i) => i !== 5);
            const secondEngagment = secondTable.filter((item, i) => i === 5);
            return (
                <>
                    <div className={style.pageWrapper}>
                        <Report downloadFilename={`${practiceLabel}_${user}`} showPagination>
                            <Page>
                                <div className={style.reportPage}>
                                    {this.renderTitle()}
                                    <table className={`table table-bordered ${style.mainTable}`}>
                                        <thead>{this.renderTableHead()}</thead>
                                        <tbody>
                                            {firstOmitEngagment.map(item => {
                                                return <>{item}</>;
                                            })}
                                        </tbody>
                                    </table>
                                    <table className={`table table-bordered ${style.mainTable}`}>
                                        <thead>{this.renderTableHead()}</thead>
                                        <tbody>
                                            {firstEngagment.map(item => {
                                                return <>{item}</>;
                                            })}
                                        </tbody>
                                    </table>
                                    {this.renderSubText()}
                                    <div className={style.pageNumber}>1</div>
                                </div>
                            </Page>

                            <Page>
                                <div className={style.reportPage}>
                                    {this.renderTitle()}
                                    <table className={`table table-bordered ${style.mainTable}`}>
                                        <thead>{this.renderTableHead()}</thead>
                                        <tbody>
                                            {secondOmitEngagment.map(item => {
                                                return <>{item}</>;
                                            })}
                                        </tbody>
                                    </table>
                                    <table className={`table table-bordered ${style.mainTable}`}>
                                        <thead>{this.renderTableHead()}</thead>
                                        <tbody>
                                            {secondEngagment.map(item => {
                                                return <>{item}</>;
                                            })}
                                        </tbody>
                                    </table>
                                    {this.renderSubText()}
                                    <div className={style.pageNumberTwo}>2</div>
                                </div>
                            </Page>
                        </Report>
                    </div>
                </>
            );
        }
    };

    getScoreColor = score => {
        let color;

        if (score < 50) {
            color = '#931D22';
        } else if (score >= 50 && score < 85) {
            color = '#D78628';
        } else if (score >= 85) {
            color = '#437337';
        }
        return color;
    };

    render() {
        const { isPreview } = this.props;
        const { loading, observation } = this.state;

        if (isPreview || !observation) {
            return (
                <div>
                    <h3>Practice Report</h3>
                </div>
            );
        }

        if (loading) {
            return <Loader loading />;
        }

        return <div>{this.renderTables()}</div>;
    }
}

export default playerWrapper({ validate })(Player);
